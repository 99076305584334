import { LoadingImage } from 'components/Loading'
import { useApi, useDropDownToggler } from 'hooks'
import { useCallback } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { askQuestion } from 'utils/alert'

type DropshipActionButtonProps = {
  selectedRows: string[]
  onOrderModified: () => void
}

const DropshipActionButton = (props: DropshipActionButtonProps) => {
  const { selectedRows, onOrderModified } = props

  const toggler = useDropDownToggler()

  const createApi = useApi({ action: 'dropshipOrders_create' }, null, {
    errorModal: true,
  })

  const handleClickCreateOrder = useCallback(async () => {
    if (
      !(await askQuestion(
        'Are you sure you want send this order to the dropshipper?',
      ))
    ) {
      return
    }

    await createApi.performRequest({ json: { id: selectedRows[0] } })

    if (onOrderModified) {
      onOrderModified()
    }
  }, [selectedRows, createApi, onOrderModified])

  const disabled = selectedRows.length !== 1

  const isLoading = createApi.isLoading

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret className={disabled ? 'disabled' : ''}>
        Dropship {isLoading ? <LoadingImage small /> : null}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleClickCreateOrder}>
          Create Dropship Order
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default DropshipActionButton
