import { SelectOneFilterButton, TagFilterButton } from 'components'
import DateRangeFilterButton from 'components/Filters/DateRangeFilterButton'
import BatchFilterButton from './BatchFilterButton'
import ProductsFilterButton from './ProductsFilterButton'
import ScheduledForFilterButton from './ScheduledForFilterButton'
import StatusFilterButton from './StatusFilterButton'
import ZoneFilterButton from './ZoneFilterButton'
import { useMemo } from 'react'

export type ManageOrdersFiltersType = FiltersTypeWithUrlUpdater & {
  q: FilterType
  batch: FilterType
  zones: FilterType
  tags: FilterType
  orderDate: FilterType
  scheduledFor: FilterType
  status: FilterType
  av: FilterType
  products: FilterType
  availability: FilterType
}

type FiltersProps = {
  filters: ManageOrdersFiltersType
  filterStatus: string
  tags: ApiTag[]
  visibleColumns: string[]
}

export const Filters = (props: FiltersProps) => {
  const { filterStatus, tags, filters, visibleColumns } = props

  const hasDropshipColumn = visibleColumns.includes('isDropshipable')
  const availabilityOptions = useMemo(
    () =>
      [
        { value: '0', label: 'Items unavailable' },
        { value: '1', label: 'Items available' },
        hasDropshipColumn
          ? { value: 'DS1', label: 'Has available DS Items' }
          : null,
      ].filter((a) => a != null),
    [hasDropshipColumn],
  )

  return (
    <>
      <BatchFilterButton {...{ filter: filters.batch }} />
      <ZoneFilterButton {...{ filter: filters.zones }} />
      <TagFilterButton {...{ tags, filter: filters.tags }} />
      <DateRangeFilterButton
        label="Order Date"
        {...{ filter: filters.orderDate }}
      />
      <ScheduledForFilterButton {...{ filter: filters.scheduledFor }} />
      <SelectOneFilterButton
        {...{
          filter: filters.availability,
          label: 'Availability',
          options: availabilityOptions,
        }}
      />
      <ProductsFilterButton {...{ filter: filters.products }} />
      {['all', 'processing', 'scheduled'].includes(filterStatus) ? (
        <StatusFilterButton
          {...{ filter: filters.status, internalOnly: filterStatus !== 'all' }}
        />
      ) : null}
      <SelectOneFilterButton
        showValueWithPrefix
        {...{
          filter: filters.av,
          label: 'AV',
          options: [
            { value: 'verified', label: 'Verified' },
            { value: 'warning', label: 'Warning' },
            { value: 'error', label: 'Error' },
          ],
        }}
      />
    </>
  )
}

export default Filters
