export const RenderJSON = ({ data }) => {
  const renderNode = (value) => {
    if (Array.isArray(value)) {
      return (
        <div>
          {value.map((item, index) => (
            <div style={{ marginBottom: '1rem' }} key={index}>
              {renderNode(item)}
            </div>
          ))}
        </div>
      )
    }

    if (typeof value === 'object' && value !== null) {
      return (
        <div style={{ paddingLeft: '1rem' }}>
          {Object.keys(value).map((key) => (
            <div key={key}>
              <strong>{key}:</strong> {renderNode(value[key])}
            </div>
          ))}
        </div>
      )
    }

    return <span>{String(value)}</span>
  }

  return <div>{renderNode(data)}</div>
}
