import valid from 'card-validator'

const checkIsNumeric = (n: any) => {
  return !isNaN(Number.parseFloat(n)) && isFinite(n)
}

export const validateField = (props: {
  value: any
  required?: boolean
  isNumeric?: boolean
  isPercentage?: boolean
  isCcNumber?: boolean
  minLength?: number
  isZipCode?: boolean
  isEmail?: boolean
  isPhoneNumber?: boolean
  [x: string]: any
}) => {
  const {
    value,
    required,
    isNumeric,
    isPercentage,
    isCcNumber,
    minLength,
    isZipCode,
    isEmail,
    isPhoneNumber,
  } = props

  if (required && !value) {
    return false
  }

  if (!required && !value) {
    return true
  }

  if ((isNumeric || isPercentage) && !checkIsNumeric(value)) {
    return false
  }

  if (isCcNumber) {
    const ret = valid.number(value)
    if (!ret.isValid) {
      return false
    }
  }

  if (minLength > 0 && String(value).length < minLength) {
    return false
  }

  if (isPercentage) {
    return Number(value) >= 0 && Number(value) <= 100
  }

  if (isZipCode && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)) {
    return false
  }

  if (isEmail && !/(.+)@(.+){2,}\.(.+){2,}/.test(value)) {
    return false
  }

  if (isPhoneNumber && value.replace(/[^0-9]/g, '').length !== 10) {
    return false
  }

  return true
}
