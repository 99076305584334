import { FilterBar, PageNavHead, PageNavTail } from 'components'
import {
  Table,
  setEditCell,
  useTable,
  useTableApi,
  useTableColumns,
} from 'components/Table'
import ManageColumnsPanel from 'containers/common/ManageColumnsPanel'
import {
  useEffectIfObjectChanges,
  useFiltersWithUrlUpdater,
  usePageTitle,
  usePanelControl,
  useSidebar,
} from 'hooks'
import { useCallback, useMemo } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { getStorage } from 'utils/storage'
import Filters, { type ManageDropshipOrdersType } from './Filters'
import columnDef from './columnDef'
import find from 'lodash/find'
import Sidebar from './Sidebar'
import SyncActionButton from './Actions/SyncActionButton'
import OrderActionButton from './Actions/OrderActionButton'
import TableExportActionButton from 'components/TableExportActionButton'

type ManageDropshipOrdersProps = {
  readOnly: boolean
  pageTitle: string
  storageKey: string
}

const visibleFilters = ['q']

const ManageDropshipOrders = (props: ManageDropshipOrdersProps) => {
  const { pageTitle, storageKey } = props

  const filters = useFiltersWithUrlUpdater(
    'manageDropshipOrdersFilters',
    visibleFilters,
  ) as ManageDropshipOrdersType

  const query = filters.searchQueryString

  const [setItem, getItem] = useMemo(() => getStorage(storageKey), [storageKey])

  const [state, dispatch] = useTable({
    getItem,
    setItem,
    cacheKey: storageKey,
  })
  const { isLoading, sortColumn, sortAsc, rows } = state

  const columns = useTableColumns(storageKey, state, dispatch, columnDef)

  const requestParams = useMemo(
    () => ({ query: '', ...filters.requestProps }),
    [filters.requestProps],
  )

  const { selectedRows } = state

  const [triggerSearch] = useTableApi(
    'dropshipOrders_find',
    state,
    dispatch,
    requestParams,
    { autoReloadInterval: false },
  )

  const sidebar = useSidebar({
    openForSingleRow: true,
    selectedRows,
  })

  /* Trigger search when filters or columns change. */
  useEffectIfObjectChanges(triggerSearch, requestParams)

  usePageTitle(pageTitle)

  const manageColumnsPanel = usePanelControl()

  const handleChangeSearch = useCallback(
    (query: string) => {
      filters.q.setValues([query])
    },
    [filters.q],
  )

  const handleClickCell = useCallback(
    (rowId: string, colId: string) => {
      if (colId === 'ttc_product_variation_id') {
        dispatch(setEditCell(rowId, colId))
        return true
      }
      return false
    },
    [dispatch],
  )

  const selectedRow =
    selectedRows.length === 1 ? find(rows, { id: selectedRows[0] }) : null

  return (
    <>
      <PageNavHead
        {...{
          isLoading,
          pageTitle,
          onClickReload: triggerSearch,
          sidebar,
        }}
      />
      <PageNavTail
        {...{
          isLoading,
          query,
          handleChangeSearch,
        }}
      >
        <OrderActionButton
          {...{
            selectedRows,
            onModified: triggerSearch,
          }}
        />
        <SyncActionButton
          {...{
            selectedRows,
            onModified: triggerSearch,
          }}
        />
        <TableExportActionButton
          {...{
            requestProps: filters.requestProps,
            query,
            sortColumn,
            sortAsc,
            columns,
            action: 'dropshipOrders_export',
          }}
        />
      </PageNavTail>
      <FilterBar {...{ filters, manageColumnsPanel, columns }}>
        <Filters
          {...{
            filters,
            visibleColumnIds: columns.visibleColumnsIds,
          }}
        />
      </FilterBar>
      <Container fluid>
        <div className="mt-4 manage-inventory animated fadeIn">
          <ManageColumnsPanel
            {...{ columns }}
            isOpen={manageColumnsPanel.isOpen}
            onClose={manageColumnsPanel.close}
          />
          <Row>
            <Col>
              <Table
                entityName="orders"
                onClickCell={handleClickCell}
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef,
                  dispatch,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
      <Sidebar {...{ onToggle: sidebar.userToggle, data: selectedRow }} />
    </>
  )
}

export default ManageDropshipOrders
