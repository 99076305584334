import { apiRequest } from 'api'
import { useEffectOnce, useInterval } from 'hooks'
import { useState } from 'react'
import { assetPath } from 'utils'
import './style.scss'
import { encodeQueryString, getApiBaseUrl } from 'api'
import moment from 'moment'

const toOrdinal = (num: string) => {
  const int = Number.parseInt(num),
    digits = [int % 10, int % 100],
    ordinals = ['st', 'nd', 'rd', 'th'],
    oPattern = [1, 2, 3, 4],
    tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
    ? int + ordinals[digits[0] - 1]
    : int + ordinals[3]
}

type GetDailyLeaderboardResponse = {
  date: string
  package_count: string
  nickname: string
}

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([])
  const [ath, setAth] = useState<GetDailyLeaderboardResponse>()

  const fetchUpdate = async () => {
    try {
      const date =
        document.location.search.indexOf('?date=') === 0
          ? document.location.search.substring(6)
          : ''
      const ret = await apiRequest({
        action: 'shippingreport_getDailyLeaderboard',
        date,
      })

      if (ret) {
        if (ret.leaderboard) {
          setLeaderboard(ret.leaderboard)
        }
        if (ret.ath && ret.ath.length) {
          const ath = ret.ath[0]
          ath.date = moment(ath.date).format('M/D/YY')
          setAth(ath)
        }
      }
    } catch (_e) {
      /* Ignore */
    }
  }

  useEffectOnce(() => {
    document.body.classList.add('leaderboard-body')
    document.body.style.backgroundImage =
      "url('/assets/img/bg-leaderboard-sky.png')"
    fetchUpdate()
  })

  useInterval(() => {
    fetchUpdate()
  }, 30000)

  const date =
    document.location.search.indexOf('?date=') === 0
      ? document.location.search.substring(6)
      : ''

  const exportParams = {
    action: 'treeadmin_shippingreport_exportLeaderboard',
    date,
  }

  return (
    <div className="leaderboard">
      <div className="leaderboard__wrap">
        {ath ? (
          <>
            <h1>All time high</h1>
            <table
              cellSpacing="0"
              cellPadding="0"
              className="leaderboard__list"
            >
              <tbody>
                <tr className="leaderboard__list-item">
                  <td className="leaderboard__list-item-ath leaderboard__list-item-ath-date">
                    {ath.date}
                  </td>

                  <td className="leaderboard__list-item-ath leaderboard__list-item-ath-count">
                    {ath.package_count}
                  </td>

                  <td className="leaderboard__list-item-ath leaderboard__list-item-ath-name">
                    {ath.nickname}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : null}
      </div>
      <div className="leaderboard__wrap">
        <h1>Leaderboard</h1>
        <table cellSpacing="0" cellPadding="0" className="leaderboard__list">
          <tbody>
            {leaderboard.map((item, i) => {
              const n = i + 1
              const position = toOrdinal(String(n))

              return (
                <tr key={item.nickname} className="leaderboard__list-item">
                  <td className="leaderboard__list-item-position">
                    {position}
                  </td>

                  <td className="leaderboard__list-item-name">
                    {item.nickname}
                  </td>

                  <td className="leaderboard__list-item-count">
                    {Number.parseInt(item.package_count, 10)}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <a
        style={{
          color: 'transparent',
          position: 'fixed',
          right: '15px',
          top: '15px',
        }}
        target="noopener"
        href={`${getApiBaseUrl()}/?${encodeQueryString(exportParams)}`}
      >
        <img src={assetPath('img/download.png')} alt="Download" />
      </a>
    </div>
  )
}

export default Leaderboard
