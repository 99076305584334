import type { ColumnDef } from 'components/Table/types'
import { Badge } from 'reactstrap'

const columnDef: ColumnDef[] = [
  {
    label: '',
    id: 'checkbox',
    sortColumn: false,
    canExport: false,
  },
  {
    label: 'ID',
    id: 'id',
    sortColumn: true,
    defaultVisible: false,
    hasClipboardButton: true,
  },
  {
    label: 'Order ID',
    id: 'order_id',
    sortColumn: true,
    defaultVisible: false,
    hasClipboardButton: true,
  },
  {
    label: 'Order Number',
    id: 'order_number',
    sortColumn: true,
    defaultVisible: true,
    hasClipboardButton: true,
  },
  {
    label: 'Customer name',
    id: 'customer_name',
    sortColumn: true,
    defaultVisible: true,
  },
  {
    label: 'Buyer Order ID',
    id: 'md_order_id',
    sortColumn: true,
    defaultVisible: true,
    hasClipboardButton: true,
  },
  {
    label: 'Status',
    id: 'status',
    sortColumn: false,
    defaultVisible: true,
    renderer: ({ row }) => {
      return (
        <td>
          {Number(row.is_partly_fulfilled) === 1 ? (
            <Badge color="warning">PARTLY FULFILLED</Badge>
          ) : null}
          {Number(row.is_fulfilled) === 1 ? (
            <Badge color="success">FULFILLED</Badge>
          ) : null}
          {Number(row.is_partly_cancelled) === 1 ? (
            <Badge color="danger">PARTLY CANCELLED</Badge>
          ) : null}
          {Number(row.is_cancelled) === 1 ? (
            <Badge color="danger">CANCELLED</Badge>
          ) : null}
        </td>
      )
    },
  },
  {
    label: 'Created at',
    id: 'created_at',
    sortColumn: true,
    defaultVisible: true,
  },
]

export default columnDef
