import { EditPanel, EditPanelCard, Portal } from 'components'
import { RenderJSON } from '../utils'

const Sidebar = (props) => {
  const { onToggle, data } = props

  return (
    <Portal id="sidebar-content">
      <ul className="nav nav-tabs flex-row-reverse">
        <li className="nav-item">
          <a onClick={onToggle} className="active nav-link">
            <i className="icon-list" />
          </a>
        </li>
      </ul>

      {data == null ? (
        <div className="tab-content">
          <div className="p-3">No order selected.</div>
        </div>
      ) : (
        <div className="tab-content pb-4">
          <EditPanel embedded isOpen caption={`Order #${data.id}`}>
            <EditPanelCard
              stateId="order_summary"
              caption="Summary"
              bodyProps={{ style: { padding: '1rem 0' } }}
            >
              <RenderJSON
                data={data.data_compact ? JSON.parse(data.data_compact) : null}
              />
            </EditPanelCard>
            <EditPanelCard
              stateId="order_data_info"
              caption="Data"
              bodyProps={{ style: { padding: '1rem 0' } }}
            >
              <RenderJSON data={data.data ? JSON.parse(data.data) : null} />
            </EditPanelCard>
          </EditPanel>
        </div>
      )}
    </Portal>
  )
}

export default Sidebar
