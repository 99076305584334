import { DropDownFilterButton, SelectOneFilterButton } from 'components/index'
import type { ColumnDef } from 'components/Table/types'

export const TableFilter = <T extends FiltersType>({
  filters,
  column,
}: { filters: T; column: ColumnDef }) => {
  const { filterDef } = column

  if (!filterDef) {
    return null
  }

  if (filterDef.type === 'select-one') {
    return (
      <SelectOneFilterButton
        label={column.label}
        options={filterDef.options}
        filter={filters[column.id]}
      />
    )
  }

  if (filterDef.type === 'select-multi') {
    return (
      <DropDownFilterButton
        label={column.label}
        options={filterDef.options}
        filter={filters[column.id]}
      />
    )
  }

  return null
}
