import type { ColumnDef } from 'components/Table/types'
import { TableFilter } from './TableFilter'

export const TableFilterRenderer = <T extends FiltersType>({
  columnDef,
  filters,
}: { filters: T; columnDef: ColumnDef[] }) => {
  return columnDef.map((column) => (
    <TableFilter<T> key={column.id} filters={filters} column={column} />
  ))
}

export default TableFilterRenderer
