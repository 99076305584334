import type { ZonesGetAllResponse } from '@ttc/api/zones'
import type {
  TableCellChangeEventProps,
  TableCellRenderProps,
} from 'app/components/Table/types'
import { FilterBar, LinkCell, PageNavHead, PageNavTail } from 'components'
import {
  Table,
  defaultCellRenderer,
  setCellLoading,
  setCellValue,
  setEditCell,
  useTable,
  useTableApi,
  useTableColumns,
} from 'components/Table'
import {
  useApi,
  useEffectIfObjectChanges,
  useFiltersWithUrlUpdater,
  usePageTitle,
  usePanelControl,
  useStateful,
} from 'hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { getStorage } from 'utils/storage'
import ZoneCell from '../EditPurchaseOrder/DeliveriesTable/TruckItemsTable/ZoneCell'
import SupplierEditor from '../ManageSuppliers/SupplierEditor'
import ManageColumnsPanel from '../common/ManageColumnsPanel'
import Filters, { type ManagePurchaseOrdersFiltersType } from './Filters'
import { getColumnDef } from './columnDef'
import TableExportActionButton from 'components/TableExportActionButton'

const pageTitle = 'Manage Deliveries'

const storageKey = 'manageDeliveries'

const [setItem, getItem] = getStorage(storageKey)

const defaultMaxYear = new Date().getFullYear()

const visibleFilters = ['q', 'year']

const ManageDeliveries = (props: { readOnly: boolean }) => {
  const { readOnly } = props

  const apiLoadZones = useApi<ZonesGetAllResponse>(
    () => ({ action: 'zones_getAll' }),
    null,
    () => ({
      autoPerform: true,
      cache: true,
    }),
  )

  const zones = useMemo(
    () => apiLoadZones.result?.rows || [],
    [apiLoadZones.result],
  )

  const manageColumnsPanel = usePanelControl()

  const filters = useFiltersWithUrlUpdater(
    'manageDeliveriesFilters',
    visibleFilters,
  ) as ManagePurchaseOrdersFiltersType

  const query = filters.searchQueryString

  const [state, dispatch] = useTable({
    cacheKey: 'manageDeliveries',
    getItem,
    setItem,
  })
  const { isLoading, sortColumn, sortAsc } = state

  const columnDef = useMemo(() => getColumnDef(readOnly), [readOnly])

  const columns = useTableColumns(storageKey, state, dispatch, columnDef)

  const filterRequestProps: Record<string, string> = useMemo(
    () => ({
      filterYear: String(defaultMaxYear),
      ...filters.requestProps,
    }),
    [filters.requestProps],
  )

  const tableQuery = useMemo(
    () => ({
      query: '',
      ...filterRequestProps,
      columns: columns.visibleColumnsIds,
    }),
    [columns.visibleColumnsIds, filterRequestProps],
  )

  const [triggerSearch] = useTableApi(
    'purchaseOrderTrucks_getAll',
    state,
    dispatch,
    tableQuery,
  )
  usePageTitle(pageTitle)

  const maxYear = useStateful(defaultMaxYear)

  useEffect(() => {
    if (state.response?.maxYear && state.response.maxYear !== maxYear.value) {
      maxYear.set(state.response.maxYear)
    }
  }, [state.response, maxYear])

  /* Trigger search when filters change. */
  useEffectIfObjectChanges(triggerSearch, filters.requestProps)

  /* Trigger search when visible columns change. */
  useEffectIfObjectChanges(triggerSearch, columns.visibleSorted)

  const handleChangeSearch = useCallback(
    (query: string) => {
      filters.q.setValues([query])
    },
    [filters.q],
  )

  const [selectedSupplierId, setSelectedSupplierId] = useState<string>(null)

  const handleClickSupplier = useCallback((rowId: string) => {
    setSelectedSupplierId(rowId)
  }, [])

  const handleCloseSupplierPanel = useCallback(() => {
    setSelectedSupplierId(null)
  }, [])

  const setFreightCharge = useApi(
    () => ({ action: 'purchaseOrderTrucks_setFreightCharge' }),
    null,
    () => ({
      errorModal: true,
      allowMultiple: true,
    }),
  )

  const handleClickCell = useCallback(
    (rowId: string, colId: string) => {
      if (colId === 'freight_charge') {
        dispatch(setEditCell(rowId, colId))
        return true
      }
    },
    [dispatch],
  )

  const performSetFreightCharge = setFreightCharge.performRequest
  const handleChangeCell = useCallback(
    async (props: TableCellChangeEventProps) => {
      const { rowId, colId, value, oldValue } = props

      dispatch(setCellLoading(rowId, colId))
      dispatch(setCellValue(rowId, colId, value))

      const ret = await performSetFreightCharge({
        id: rowId,
        value,
        oldValue,
      })

      if (ret === false) {
        dispatch(setCellValue(rowId, colId, oldValue))
      } else if (ret && Object.hasOwn(ret, 'freight_charge')) {
        dispatch(setCellValue(rowId, colId, ret.freight_charge))
      }
      dispatch(setCellLoading())

      triggerSearch()
    },
    [dispatch, performSetFreightCharge, triggerSearch],
  )

  const renderCell = useCallback(
    (props: TableCellRenderProps) => {
      if (props.col.id === 'truck_name') {
        return (
          <td className="cell id">
            <Link
              to={`/purchase-orders/edit/${props.row.purchase_order_id}/deliveries/${props.row.id}`}
              state={{ fromDeliveries: true }}
            >
              {props.value}
            </Link>
          </td>
        )
      } else if (props.col.id === 'purchase_order_id') {
        return (
          <td className="cell id">
            <Link
              to={`/purchase-orders/edit/${props.value}`}
              state={{ fromDeliveries: true }}
            >
              {props.value}
            </Link>
          </td>
        )
      } else if (props.col.id === 'supplier') {
        return (
          <LinkCell id={props.row.supplier_id} onClick={handleClickSupplier}>
            {props.value}
          </LinkCell>
        )
      } else if (props.col.id === 'zones') {
        return (
          <ZoneCell
            {...{
              zones,
              zoneQty: props.value,
            }}
          />
        )
      }

      return defaultCellRenderer(props)
    },
    [handleClickSupplier, zones],
  )

  return (
    <>
      <PageNavHead
        {...{ isLoading, pageTitle, onClickReload: triggerSearch }}
      />
      <PageNavTail
        {...{
          isLoading,
          query,
          handleChangeSearch,
        }}
      >
        <TableExportActionButton
          {...{
            requestProps: filterRequestProps,
            query,
            sortColumn,
            sortAsc,
            columns,
            action: 'purchaseOrderTrucks_export',
          }}
        />
      </PageNavTail>
      <FilterBar {...{ filters, manageColumnsPanel, columns }}>
        <Filters {...{ filters, maxYear: maxYear.value }} />
      </FilterBar>
      <Container fluid className="mt-4">
        <div className="manage-orders animated fadeIn">
          <SupplierEditor
            readOnly
            editId={selectedSupplierId}
            isOpen={selectedSupplierId != null}
            onClose={handleCloseSupplierPanel}
          />
          <ManageColumnsPanel
            {...{ columns }}
            isOpen={manageColumnsPanel.isOpen}
            onClose={manageColumnsPanel.close}
          />
          <Row>
            <Col>
              <Table
                onChangeCell={handleChangeCell}
                onClickCell={handleClickCell}
                entityName="orders"
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef,
                  dispatch,
                  renderCell,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default ManageDeliveries
