import type { Identifier, XYCoord } from 'dnd-core'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

type ItemDropTargetProps = {
  id: string
  index: number
  moveItem: (dragIndex: number, hoverIndex: number) => void
}

// Based on https://react-dnd.github.io/react-dnd/examples/sortable/simple
export const Item = ({ id, onClick, index, moveItem, children, title }) => {
  const ref = useRef<HTMLLIElement>(null)
  const [{ handlerId }, drop] = useDrop<
    ItemDropTargetProps,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'item',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: ItemDropTargetProps, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'item',
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <li
      ref={ref}
      onClick={onClick}
      data-id={id}
      className="list-group-item"
      style={{ cursor: 'pointer', opacity }}
      data-handler-id={handlerId}
      title={title}
    >
      {children}
    </li>
  )
}

export default Item
