import type { Product } from '@ttc/api/products'
import { EditPanelCard } from 'components'
import type { ApiCall } from 'hooks'
import { useCallback } from 'react'
import { Badge, Col, Row } from 'reactstrap'
import LineItem from './../LineItem'

type OrderSplitCardProps = {
  index: number
  split: OrderSplitCardSplit
  onClickCancel: any
  showDropshipAvailability: boolean
}

type OrderSplitCardSplit = {
  orderNumber: string
  isEditable: boolean
  splitStatus?: string
  status?: string
  items: OrderSplitCardLineItem[]
}

type OrderSplitCardLineItem = {
  id: string
  product_data: Product
  num_unavailable: number
  num_ds_unavailable: number
  quantity: number
  sku: string
  showAvailability?: boolean
}

const OrderSplitCard = ({
  index,
  split,
  onClickCancel,
  showDropshipAvailability,
}: OrderSplitCardProps) => {
  const handleClickCancel = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()

      if (onClickCancel) {
        onClickCancel(split.orderNumber)
      }
    },
    [onClickCancel, split],
  )

  const caption = (
    <span>
      {split.orderNumber}{' '}
      <Badge color="warning">{split.splitStatus || split.status}</Badge>
      {onClickCancel && split.isEditable ? (
        <a
          href="#"
          className="text-danger ml-2"
          style={{ letterSpacing: 'normal', textTransform: 'none' }}
          onClick={handleClickCancel}
        >
          Cancel
        </a>
      ) : null}
    </span>
  )

  return (
    <EditPanelCard
      caption={caption}
      stateId={`orders_split_${index}`}
      bodyProps={{ className: 'p-0' }}
    >
      <Row>
        <Col>
          {split.items.map((item: OrderSplitCardLineItem, i: number) => (
            <LineItem
              key={item.id}
              isLast={i === split.items.length - 1}
              item={item}
              showAvailability={
                split.isEditable && item.showAvailability !== false
              }
              showDropshipAvailability={showDropshipAvailability}
            />
          ))}
        </Col>
      </Row>
    </EditPanelCard>
  )
}

type OrderSplitCardsProps = {
  getOrder: ApiCall
  splits?: OrderSplitCardSplit[]
  onClickCancel: (splitOrderNumber: string) => void
  showDropshipAvailability: boolean
}

const OrderSplitCards = (props: OrderSplitCardsProps) => {
  const { getOrder, onClickCancel, showDropshipAvailability } = props
  const { result } = getOrder

  if (!result.orderSplits) {
    return null
  }

  return result.orderSplits.map((split: any, index: number) => (
    <OrderSplitCard
      key={split.orderNumber}
      {...{ index, split, onClickCancel, showDropshipAvailability }}
    />
  ))
}

export default OrderSplitCards
