import type { GroupBase, StylesConfig } from 'react-select'

export function getStyles<Option = string, IsMulti extends boolean = true>({
  highlightIfEmpty,
  isSmall,
}: { highlightIfEmpty?: boolean; isSmall?: boolean } = {}): StylesConfig<
  Option,
  IsMulti,
  GroupBase<Option>
> {
  return {
    control: (base, state) => {
      return {
        ...base,
        borderColor:
          highlightIfEmpty && !state.hasValue
            ? 'red'
            : state.isFocused
              ? '#73c8fd'
              : '#c5d8e7',
        '&:hover': {
          borderColor: highlightIfEmpty && !state.hasValue ? 'red' : '#c5d8e7',
        },
        boxShadow: state.isFocused
          ? '0 0 0 0.2rem rgba(3, 147, 238, 0.25)'
          : base.boxShadow,
      }
    },
    valueContainer: (base) => ({
      ...base,
      minHeight: isSmall ? 38 : 45,
    }),
    placeholder: (base) => ({
      ...base,
      color: '#96a1ab',
    }),
  }
}

export const styles = getStyles()

export const stylesSmall = getStyles({ isSmall: true })

export default styles
