import { useEffect, useReducer } from 'react'
import { fetchCustomerDetails } from './api'
import { calculateTotals } from './totals'
import {
  shouldCheckBillingSameAsShipping,
  validateAddresses,
  validatePayment,
} from './validate'
export { fetchCustomerDetails }
import moment from 'moment'
import { formatPrice } from 'utils/price'
import {
  getFreeShippingThresholdCents,
  getOversizeFeeCents,
  getShippingRateCents,
  getWarrantyPercent,
} from 'utils/settings'
import importOrder from './importOrder'
import type {
  Action,
  CartItemAction,
  CustomerState,
  SetValueAction,
  State,
} from './types'

const shouldPersistState = () =>
  window.location.pathname.indexOf('/orders/add') === 0

const init = (state: State) => {
  const restoredState = shouldPersistState()
    ? JSON.parse(
        localStorage.getItem(`shoppingCart.${state.context || ''}`) || '{}',
      )
    : {}

  return {
    ...state,
    ...restoredState,
    orderProgress: '',
  }
}

const resetCustomerState: CustomerState = {
  shippingFirstName: '',
  shippingLastName: '',
  shippingCompany: '',
  shippingAddress: '',
  shippingAddress2: '',
  shippingCity: '',
  shippingState: '',
  shippingZip: '',
  shippingPhone: '',
  shippingEmail: '',
  isShippingAddressValid: false,
  isBillingSameAsShipping: true,
  billingFirstName: '',
  billingLastName: '',
  billingCompany: '',
  billingAddress: '',
  billingAddress2: '',
  billingCity: '',
  billingState: '',
  billingZip: '',
  isBillingAddressValid: false,

  cardsOnFileId: null,
  cardsOnFile: [],
  isStoreCredit: false,
  isCardOnFile: false,
  ccNumber: '',
  ccMonth: 0,
  ccYear: 0,
  ccCvv: '',
  isPaymentValid: false,
  isPaymentEmpty: false,
}

export const getInitialState = (): State => {
  return {
    context: null,
    customer: null,

    ...resetCustomerState,

    orderId: '',
    orderNumber: '',
    orderNumberInput: '',
    orderStatus: '',
    reloadOrder: false,
    total: 0,
    originaltotal: null,
    originaltotal_calculated: null,
    subtotal: 0,
    orderProgress: '',
    orderPlaced: false,

    cartItems: [],

    editCartItem: null,
    isUpdatingCartItem: false,

    hasShipping: false,
    shippingModified: false,
    shipping: `${getShippingRateCents() / 100}`,
    shippingCalculated: 0,

    hasTax: false,
    isCustomTax: false,
    tax: 0,

    hasWarranty: true,
    warranty: `${getWarrantyPercent()}%`,
    warrantyCalculated: 0,
    warrantyPercentCalculated: 0,

    hasDiscount: false,
    discount: '0',
    discountCalculated: 0,
    discountPercentCalculated: 0,
    coupons: [],

    hasOversizeFee: false,
    oversizeFee: '0',
    oversizeFeeCalculated: 0,

    refund: 0,

    hasShipDate: false,
    shipDate: '',

    canPlaceOrder: false,
    canUpdateOrder: false,
    canCancelOrder: false,
    canCancelSplits: false,
    canPartialRefund: false,

    shouldChargeDifference: false,
    shouldUpdateQuantitiesPutBack: false,
    shouldUpdateQuantitiesRemoveFrom: false,

    refunds: [],

    lastImport: 0,

    canEdit: false,
    canEditAddressOnly: false,
    disallowEditError: false,
    warnings: [],

    returnId: null,
    isReplacementOrder: false,
  }
}

const RESET = 'RESET'
const RESET_CUSTOMER = 'RESET_CUSTOMER'
const SET_VALUE = 'SET_VALUE'
const SET_VALUES = 'SET_VALUES'
const SET_CUSTOMER_ID = 'SET_CUSTOMER_ID'
const ADD_CART_ITEM = 'ADD_CART_ITEM'
const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM'
const SET_EDIT_CART_ITEM = 'SET_EDIT_CART_ITEM'
const SET_EDIT_EXISTING_CART_ITEM = 'SET_EDIT_EXISTING_CART_ITEM'
const SET_CART_ITEM_RETURN_INVENTORY = 'SET_CART_ITEM_RETURN_INVENTORY'
const RESET_EDIT_CART_ITEM = 'RESET_EDIT_CART_ITEM'
const SET_EDIT_CART_ITEM_PRICE = 'SET_EDIT_CART_ITEM_PRICE'
const SET_EDIT_CART_ITEM_QUANTITY = 'SET_EDIT_CART_ITEM_QUANTITY'
const SET_EDIT_CART_ITEM_WARRANTY = 'SET_EDIT_CART_ITEM_WARRANTY'
const IMPORT_EDIT_ORDER = 'IMPORT_EDIT_ORDER'
const SET_RETURN_ID = 'SET_RETURN_ID'
const TOGGLE_IS_REPLACEMENT_ORDER = 'TOGGLE_IS_REPLACEMENT_ORDER'

const setValueReducer = (state: any, action: SetValueAction) => {
  if (action.name === 'customer') {
    if (action.value === null) {
      return getInitialState()
    }

    return { ...state, customer: action.value }
  }

  if (action.name === 'isStoreCredit' && action.value) {
    return { ...state, isStoreCredit: action.value, isCardOnFile: false }
  }

  if (action.name === 'isCardOnFile' && action.value) {
    return { ...state, isStoreCredit: false, isCardOnFile: action.value }
  }

  if (action.name === 'hasShipping' || action.name === 'shipping') {
    return { ...state, [action.name]: action.value, shippingModified: true }
  }

  /* Automatically enable hasTax if billing state is Maryland. */
  if (
    action.name === 'shippingState' ||
    action.name === 'billingState' ||
    action.name === 'isBillingSameAsShipping'
  ) {
    const newState = { ...state, [action.name]: action.value }

    const usState = newState.isBillingSameAsShipping
      ? newState.shippingState
      : newState.billingState
    const hasTax = usState === 'MD'

    return { ...state, [action.name]: action.value, hasTax }
  }

  if (action.name === 'hasTax') {
    const hasTax = action.value
    const isCustomTax = hasTax ? state.isCustomTax : false

    return { ...state, hasTax, isCustomTax }
  }

  return { ...state, [action.name]: action.value }
}

const itemMatches = (item: any, action: CartItemAction) => {
  return (
    String(item.id) === String(action.id) &&
    String(item.lineItemId || '') == String(action.lineItemId || '')
  )
}

export const _reducer = (state: State, action: Action) => {
  switch (action.type) {
    case RESET: {
      return getInitialState()
    }
    case RESET_CUSTOMER: {
      return { ...state, ...resetCustomerState }
    }
    case SET_VALUES: {
      let newState = { ...state }

      for (const name of Object.keys(action.values)) {
        newState = setValueReducer(
          newState,
          setValue(name, action.values[name]),
        )
      }

      return newState
    }
    case SET_VALUE: {
      return setValueReducer(state, action)
    }
    case SET_CUSTOMER_ID: {
      return { ...state, customer: { ...state.customer, value: action.id } }
    }
    case SET_CART_ITEM_RETURN_INVENTORY: {
      return {
        ...state,
        cartItems: state.cartItems.map((item) => {
          if (itemMatches(item, action)) {
            return {
              ...item,
              restoreInventory: action.restoreInventory,
            }
          }

          return item
        }),
      }
    }
    case ADD_CART_ITEM: {
      const { product, quantity, price, hasWarranty } = action

      const hasExistingItem =
        state.cartItems.find((item) => itemMatches(item, action)) != null

      if (!hasExistingItem) {
        return {
          ...state,
          editCartItem: null,
          isCustomTax: false,
          hasWarranty: hasWarranty ? true : state.hasWarranty,
          cartItems: [
            ...state.cartItems,
            {
              ...product,
              quantity,
              price,
              hasWarranty,
              isEditable: true,
            },
          ],
        }
      }

      return {
        ...state,
        editCartItem: null,
        isCustomTax: false,
        hasWarranty: hasWarranty ? true : state.hasWarranty,
        cartItems: state.cartItems.map((item) => {
          if (itemMatches(item, action)) {
            return {
              ...item,
              quantity,
              price,
              hasWarranty,
            }
          }

          return item
        }),
      }
    }
    case REMOVE_CART_ITEM: {
      let canRemove = true

      for (const item of state.cartItems) {
        if (itemMatches(item, action) && item.originalQuantity > 0) {
          canRemove = false
        }
      }

      if (!canRemove) {
        return {
          ...state,
          editCartItem: null,
          isCustomTax: false,
          cartItems: state.cartItems.map((item) => {
            if (itemMatches(item, action)) {
              return {
                ...item,
                quantity: 0,
              }
            }

            return item
          }),
        }
      }

      return {
        ...state,
        editCartItem: null,
        cartItems: state.cartItems.filter((item) => {
          return !itemMatches(item, action)
        }),
        isCustomTax: false,
      }
    }
    case SET_EDIT_CART_ITEM: {
      return {
        ...state,
        isUpdatingCartItem: false,
        editCartItem: {
          ...action.product,
          quantity: 1,
          customPrice: Number.parseFloat(
            String(action.product.price / 100),
          ).toFixed(2),
          hasWarranty: action.product.allowWarranty,
        },
      }
    }
    case SET_EDIT_EXISTING_CART_ITEM: {
      const cartItem = state.cartItems.find((item) => itemMatches(item, action))

      if (cartItem == null) {
        return state
      }

      return {
        ...state,
        editCartItem: {
          ...cartItem,
          customPrice: Number.parseFloat(String(cartItem.price / 100)).toFixed(
            2,
          ),
        },
        isUpdatingCartItem: true,
      }
    }
    case RESET_EDIT_CART_ITEM: {
      return { ...state, editCartItem: null }
    }
    case SET_EDIT_CART_ITEM_PRICE: {
      return {
        ...state,
        editCartItem: {
          ...state.editCartItem,
          customPrice: action.price,
        },
      }
    }
    case SET_EDIT_CART_ITEM_QUANTITY: {
      return {
        ...state,
        editCartItem: {
          ...state.editCartItem,
          quantity: action.quantity,
        },
      }
    }
    case SET_EDIT_CART_ITEM_WARRANTY: {
      return {
        ...state,
        editCartItem: {
          ...state.editCartItem,
          hasWarranty: action.hasWarranty,
        },
      }
    }
    case IMPORT_EDIT_ORDER: {
      return importOrder(state, action)
    }
    case SET_RETURN_ID: {
      return {
        ...state,
        returnId: action.returnId,
        isReplacementOrder: action.returnId != null,
      }
    }
    case TOGGLE_IS_REPLACEMENT_ORDER: {
      return {
        ...state,
        isReplacementOrder: !state.isReplacementOrder,
      }
    }
    default:
      return state
  }
}

const calcOversizeFee = (cartItems: any[]) => {
  const oversizeFee = getOversizeFeeCents()
  const cents = cartItems
    .filter((p) => p.isOversize)
    .reduce((a, p) => a + p.quantity * oversizeFee, 0)
  return formatPrice(cents)
}

const earliestShipDate = (cartItems: any[]) => {
  let earliestDate = ''

  for (const item of cartItems) {
    if (moment(item.preorderDate).isBefore(moment())) {
      continue
    }

    if (earliestDate == '') {
      earliestDate = item.preorderDate
      continue
    }

    if (moment(item.preorderDate).isAfter(moment(earliestDate))) {
      earliestDate = item.preorderDate
    }
  }

  return earliestDate
}

export const reducer = (_state: State, action: Action): State => {
  const oldState = _state
  let state = _reducer(_state, action)

  const cartItemsModified =
    action.type !== 'IMPORT_EDIT_ORDER' &&
    JSON.stringify(oldState.cartItems) !== JSON.stringify(state.cartItems)

  if (cartItemsModified) {
    /* Recalculate oversize fee. */
    const _oversizeFee = calcOversizeFee(state.cartItems)
    state = {
      ...state,
      oversizeFee: _oversizeFee,
      hasOversizeFee: Number(_oversizeFee) > 0,
    }

    /* Set earliest shipping date. */
    if (!state.hasShipDate) {
      const shipDate = earliestShipDate(state.cartItems)
      if (shipDate != '') {
        state = { ...state, hasShipDate: shipDate != '', shipDate }
      }
    }
  }

  if (
    state.cardsOnFileId &&
    state.cardsOnFile &&
    state.cardsOnFile.find((c: any) => c.value === state.cardsOnFileId) == null
  ) {
    state = { ...state, cardsOnFileId: null }
  }

  /* Check "Billing Same As Shipping" if addresses are the same or
   * if billing address is empty. */
  if (state.isBillingSameAsShipping === null) {
    state = {
      ...state,
      isBillingSameAsShipping: shouldCheckBillingSameAsShipping(state),
    }
  }

  state = validateAddresses(state)
  state = validatePayment(state)
  state = calculateTotals(state)

  if (cartItemsModified) {
    /* Automatically toggle shipping based on threshold, unless shipping has
     * already been modified by user. */
    const disableShippingTreshold = getFreeShippingThresholdCents()
    const hasShipping = state.shippingModified
      ? state.hasShipping
      : state.subtotal <= disableShippingTreshold
    state = { ...state, hasShipping }
    state = calculateTotals(state)
  }

  const canPlaceOrder =
    (!state.hasShipDate || (state.hasShipDate && state.shipDate)) &&
    state.isPaymentValid &&
    state.isBillingAddressValid &&
    state.isShippingAddressValid &&
    state.orderProgress == '' &&
    state.orderPlaced == false &&
    state.total >= 0 &&
    state.subtotal > 0

  const needCharge = state.total > (state.originaltotal || 0)
  const isPayPal =
    state.isCardOnFile && (state.cardsOnFileId || '').indexOf('paypal:') === 0
  const isAffirm =
    state.isCardOnFile && (state.cardsOnFileId || '').indexOf('affirm:') === 0
  const hasValidRefundPayment =
    !needCharge ||
    (needCharge && state.isPaymentValid && !isPayPal && !isAffirm)

  const canUpdateOrder =
    state.canEdit &&
    (!state.hasShipDate || (state.hasShipDate && state.shipDate)) &&
    hasValidRefundPayment &&
    state.orderStatus &&
    state.isBillingAddressValid &&
    state.isShippingAddressValid &&
    state.orderProgress == '' &&
    state.orderPlaced == false &&
    state.total - state.refund >= 0 &&
    state.subtotal > 0

  const canCancelOrder =
    state.canEdit &&
    (state.orderStatus === 'processing' || state.orderStatus === 'scheduled')

  state = {
    ...state,
    canPlaceOrder,
    canUpdateOrder,
    canCancelOrder,
  }

  return state
}

export const setReturnId = (returnId: string | null): Action => {
  return { type: SET_RETURN_ID, returnId }
}

export const toggleIsReplacementOrder = (): Action => {
  return { type: TOGGLE_IS_REPLACEMENT_ORDER }
}

export const importEditOrder = (data: any): Action => {
  return { type: IMPORT_EDIT_ORDER, data }
}

export const setValue = (name: string, value: any): SetValueAction => {
  return { type: SET_VALUE, name, value }
}

export const setValues = (values: any): Action => {
  return { type: SET_VALUES, values }
}

export const setCustomerId = (id: string): Action => {
  return { type: SET_CUSTOMER_ID, id }
}

export const reset = (): Action => {
  return { type: RESET }
}

export const resetCustomer = (): Action => {
  return { type: RESET_CUSTOMER }
}

export const addCartItem = (
  product: any,
  price: number,
  quantity: number,
  hasWarranty: boolean,
): Action => {
  return {
    type: ADD_CART_ITEM,
    product,
    price,
    quantity,
    id: product.id,
    lineItemId: product.lineItemId,
    hasWarranty,
  }
}

export const removeCartItem = (id: string, lineItemId: string): Action => {
  return { type: REMOVE_CART_ITEM, id, lineItemId }
}

export const setEditCartItem = (product: any): Action => {
  return { type: SET_EDIT_CART_ITEM, product }
}

export const setEditExistingCartItem = (
  id: string,
  lineItemId: string,
): Action => {
  return { type: SET_EDIT_EXISTING_CART_ITEM, id, lineItemId }
}

export const setCartItemRestoreInventory = (
  id: string,
  lineItemId: string,
  restoreInventory: boolean,
): Action => {
  return {
    type: SET_CART_ITEM_RETURN_INVENTORY,
    id,
    lineItemId,
    restoreInventory,
  }
}

export const resetEditCartItem = (): Action => {
  return { type: RESET_EDIT_CART_ITEM }
}

export const setEditCartItemPrice = (price: string): Action => {
  return { type: SET_EDIT_CART_ITEM_PRICE, price }
}

export const setEditCartItemQuantity = (quantity: number): Action => {
  return { type: SET_EDIT_CART_ITEM_QUANTITY, quantity }
}

export const setEditCartItemWarranty = (hasWarranty: boolean): Action => {
  return { type: SET_EDIT_CART_ITEM_WARRANTY, hasWarranty }
}

export const useCart = (context: string): [State, React.Dispatch<Action>] => {
  const [state, dispatch] = useReducer<React.Reducer<State, Action>, any>(
    reducer,
    { ...getInitialState(), context },
    init,
  )

  /* For Debugging */
  global.getCartState = () => {
    return state
  }
  global.testUtils.getCartState = global.getCartState
  global.testUtils.cartDispatch = dispatch

  useEffect(() => {
    if (shouldPersistState()) {
      localStorage.setItem(`shoppingCart.${context}`, JSON.stringify(state))
    }
  }, [state, context])

  return [state, dispatch]
}

export default useCart
