import { EditPanel, EditPanelCard, Loading } from 'components'
import type { ApiCallResult } from 'hooks/useApi'
import { Col, Row } from 'reactstrap'
import LineItem from './LineItem'

export type MultiOrderResult = {
  total: number
  orders: number
  total_available: number
  items: LineItem[]
}

type MultiOrderEditorProps = {
  onToggle: (e: React.MouseEvent<HTMLElement>) => void
  getOrdersSummary: ApiCallResult<MultiOrderResult>
  showSummary: boolean
  onToggleShowSummary: () => void
}

const MultiOrderEditor = (props: MultiOrderEditorProps) => {
  const {
    onToggle,
    getOrdersSummary,
    showSummary,
    onToggleShowSummary: onToggleShowSummary,
  } = props

  const itemsSummary = getOrdersSummary.hasResult
    ? getOrdersSummary.result.total === getOrdersSummary.result.total_available
      ? ` - (${getOrdersSummary.result.total} items)`
      : ` - (${getOrdersSummary.result.total_available} of ${getOrdersSummary.result.total} available)`
    : ''
  const caption = `${
    getOrdersSummary.hasResult ? (getOrdersSummary.result.orders ?? 0) : 0
  } orders ${itemsSummary}`

  return (
    <>
      <ul className="nav nav-tabs flex-row-reverse">
        <li className="nav-item">
          <a onClick={onToggle} className="active nav-link">
            <i className="icon-list"></i>
          </a>
        </li>
      </ul>

      <div className="tab-content">
        <Row>
          <Col className="d-flex align-items-center m-2 pl-1 pr-1">
            <div className="mb-0 form-group">
              <input
                onChange={onToggleShowSummary}
                style={{
                  cursor: 'pointer',
                }}
                className="p-1"
                type="checkbox"
                checked={showSummary}
              />
              <label
                onClick={onToggleShowSummary}
                style={{ fontSize: '1rem' }}
                className="ml-2"
              >
                Show all orders summary
              </label>
            </div>
          </Col>
        </Row>

        {showSummary &&
        (getOrdersSummary.isLoading ||
          getOrdersSummary.hasResult ||
          getOrdersSummary.error !== null) ? (
          getOrdersSummary.error ? (
            <Row>
              <Col>
                <div className="text-danger">{getOrdersSummary.error}</div>
              </Col>
            </Row>
          ) : getOrdersSummary.isLoading ? (
            <Loading />
          ) : (
            <EditPanel embedded isOpen {...{ caption }}>
              {getOrdersSummary.hasResult && getOrdersSummary.result ? (
                <EditPanelCard
                  bodyProps={{ className: 'p-0' }}
                  caption="Items Ordered"
                  stateId="orders_items_ordered"
                >
                  {(getOrdersSummary.result.items || []).map((item) => {
                    return (
                      <LineItem key={item.id} item={item} showAvailability />
                    )
                  })}
                </EditPanelCard>
              ) : null}
            </EditPanel>
          )
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default MultiOrderEditor
