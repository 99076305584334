import { apiRequest } from 'api'
import {
  CellProductName,
  FilterBar,
  PageNavHead,
  PageNavTail,
} from 'components'
import { useInfoIcon } from 'components/InfoIcon/useInfoIcon'
import {
  CellEditable,
  Table,
  defaultCellRenderer,
  setCellLoading,
  setCellLoadingDone,
  setCellValue,
  setEditCell,
  useTable,
  useTableApi,
  useTableColumns,
} from 'components/Table'
import ExportInventoryActionButton from 'containers/common/Actions/ExportInventoryActionButton'
import EditProductPanel from 'containers/common/EditProductPanel'
import ManageColumnsPanel from 'containers/common/ManageColumnsPanel'
import ManageTagsPanel from 'containers/common/ManageTagsPanel'
import TagActionButton from 'containers/common/TagActionButton'
import {
  useApi,
  useBoolean,
  useEffectIfObjectChanges,
  useFiltersWithUrlUpdater,
  usePageTitle,
  usePanelControl,
} from 'hooks'
import intersection from 'lodash/intersection'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { getStorage } from 'utils/storage'
import AddActionButton from './Actions/AddActionButton'
import ImportActionButton from './Actions/ImportActionButton'
import ShortcodeActionButton from './Actions/ShortcodeActionButton'
import { useShortcodePaste } from './Actions/useShortcodePaste'
import CogsHoverCell from './CogsHover'
import Filters from './Filters'
import columnDef from './columnDef'
import DiscountActionButton from './Actions/DiscountActionButton'
import AuthContext from 'AuthContext'
import { MANAGEDISCOUNTS_READWRITE } from 'caps'

import type { ZonesGetAllResponse } from '@ttc/api/zones'
import type { Row as TableRow } from 'components/Table'
import type { Column } from 'components/Table/reducer/types'
import type { TableCellRenderProps } from 'components/Table/types'
import type { OnClickCellProductNameProps } from 'components/TableCells/CellProductName'
import type { SelectedProductProps } from 'containers/common/EditProductPanel/types'
import type { ManageInventoryFiltersType } from './Filters'

const Alert = withReactContent(Swal)

const visibleFilters = [
  'q',
  'status',
  'tags',
  'categories',
  'zones',
  'range',
  'suppliers',
  'discount',
]

type ManageInventoryProps = {
  readOnly: boolean
  pageTitle: string
  hiddenColumns?: string[]
  storageKey: string
}

const ManageInventory = (props: ManageInventoryProps) => {
  const { readOnly, pageTitle, hiddenColumns, storageKey } = props

  const { hasCap } = useContext(AuthContext)
  const canManageDiscounts = hasCap(MANAGEDISCOUNTS_READWRITE)

  const filters = useFiltersWithUrlUpdater(
    'manageInventoryFilters',
    visibleFilters,
  ) as ManageInventoryFiltersType

  const query = filters.searchQueryString

  const [setItem, getItem] = useMemo(() => getStorage(storageKey), [storageKey])

  const [state, dispatch] = useTable({
    getItem,
    setItem,
    cacheKey: storageKey,
  })
  const {
    rows,
    selectedRows,
    isLoading,
    sortColumn,
    sortAsc,
    cellsLoading,
    editCell,
    didLoad,
  } = state

  const apiLoadZones = useApi(
    () => ({ action: 'zones_getAll' }),
    null,
    () => ({ autoPerform: true }),
  )

  const apiLoadDiscounts = useApi(
    () => ({ action: 'discounts_getAll' }),
    null,
    () => ({ autoPerform: true }),
  )

  const filteredColumnDef = useMemo(() => {
    const result: ZonesGetAllResponse = apiLoadZones.result
    const zones = result ? result.rows : []

    const zoneColumns = zones.map((zone) => {
      return {
        label: zone.column_label,
        id: `zoneQty${zone.id}`,
        sortColumn: true,
        isNumeric: true,
        isZone: true,
        isEditable: zone.is_dropship === false,
        zoneId: zone.id,
        title: zone.description,
      }
    })

    return [...columnDef, ...zoneColumns].filter(
      (col) => !(hiddenColumns || []).includes(col.id),
    )
  }, [apiLoadZones, hiddenColumns])

  const columns = useTableColumns(
    storageKey,
    state,
    dispatch,
    filteredColumnDef,
  )

  const requestColumnsIds = useMemo(
    // Some columns are always required regardless of column visibility.
    () => [
      'status',
      'isBlocked',
      'tags',
      'isManagingStock',
      'thumbnailImageUrl',
      ...columns.visibleColumnsIds,
    ],
    [columns.visibleColumnsIds],
  )

  const requestParams = useMemo(
    () => ({ query: '', ...filters.requestProps, columns: requestColumnsIds }),
    [filters.requestProps, requestColumnsIds],
  )

  const [triggerSearch] = useTableApi(
    'inventory_getProducts',
    state,
    dispatch,
    requestParams,
    /* Disable auto reload because revenue queries are expensive. */
    { autoReloadInterval: false },
  )

  /* Trigger search when filters or columns change. */
  useEffectIfObjectChanges(triggerSearch, requestParams)

  usePageTitle(pageTitle)

  const editProductPanel = usePanelControl()
  const manageColumnsPanel = usePanelControl()
  const manageTagsPanel = usePanelControl()

  const handleChangeSearch = useCallback(
    (query: string) => {
      filters.q.setValues([query])
    },
    [filters.q],
  )

  // Defer reloading the whole table (which is expensive and may change row order)
  // until user is done editing multiple cells in a row.
  const isEditing = editCell != null
  const didEdit = useBoolean(false)
  useEffect(() => {
    // Don't trigger until user edited something.
    if (!didEdit.value) {
      return
    }

    // Don't trigger until initial load.
    if (!didLoad) {
      return
    }

    // Don't trigger while editing.
    if (isEditing) {
      return
    }

    // Don't trigger while cells are loading.
    if (Object.keys(cellsLoading).length > 0) {
      return
    }

    triggerSearch(true)
  }, [triggerSearch, cellsLoading, isEditing, didLoad, didEdit])

  const handleChangeCell = useCallback(
    async ({
      value,
      oldValue,
      colId,
      col,
      rowId,
    }: {
      value: string
      oldValue: string
      colId: string
      rowId: string
      col: Column
    }) => {
      try {
        didEdit.on()

        dispatch(setCellLoading(rowId, colId, value))

        await apiRequest({
          action: 'inventory_setProductValue',
          key: colId,
          productId: rowId,
          value,
          oldValue,
          zoneID: col.zoneId,
        })

        dispatch(setCellValue(rowId, colId, value))
        dispatch(setCellLoadingDone(rowId, colId))
      } catch (e) {
        dispatch(setCellLoadingDone(rowId, colId))

        await Alert.fire({ icon: 'error', title: <p>{e.message}</p> })

        triggerSearch(true)
      }
    },
    [dispatch, triggerSearch, didEdit],
  )

  const handleClickCell = useCallback(
    (rowId: string, colId: string) => {
      dispatch(setEditCell(rowId, colId))
      return false
    },
    [dispatch],
  )

  const [editProductProps, setEditProductProps] =
    useState<SelectedProductProps>(null)

  const openEditProductPanel = editProductPanel.open
  const handleClickProductName = useCallback(
    (props: OnClickCellProductNameProps) => {
      setEditProductProps(props)
      openEditProductPanel()
    },
    [openEditProductPanel],
  )

  const handleClickAddVariation = useCallback(() => {
    setEditProductProps({ addType: 'variable' })
    editProductPanel.open()
  }, [editProductPanel])

  const handleClickAddSimple = useCallback(() => {
    setEditProductProps({ addType: 'simple' })
    editProductPanel.open()
  }, [editProductPanel])

  // Handles clicks outside the cogs-icon popover
  const cogsCell = useInfoIcon('cogs-icon')

  const renderCogsCell = useCallback(
    (props: TableCellRenderProps<TableRow>) => {
      return (
        <td>
          <CogsHoverCell
            value={props.value}
            {...{
              id: props.row.id,
              dropDownState: cogsCell.dropDownState,
            }}
          />
        </td>
      )
    },
    [cogsCell],
  )

  const hasVariationDescColumn = useMemo(
    () => columns.visibleColumnsIds.includes('variationDescription'),
    [columns.visibleColumnsIds],
  )

  const renderCell = useCallback(
    (props: TableCellRenderProps<TableRow>) => {
      const { id, isEditable, isZone, renderValue } = props.col
      const { isManagingStock } = props.row

      if (id === 'costOfGoods') {
        return renderCogsCell(props)
      }

      if (id === 'qtySold') {
        const { value } = props

        return <td>{value ? value : ''}</td>
      }

      if (id === 'name') {
        const { value, row } = props

        return (
          <CellProductName
            {...{
              onClick: handleClickProductName,
              productId: Number(row.id),
              value,
              parentProductId: row.parentProductId,
              name: row.name,
              thumbnailImageUrl: row.thumbnailImageUrl,
              status: row.status,
              isBlocked: row.isBlocked,
              variationDescription: hasVariationDescColumn
                ? ''
                : row.variationDescription,
            }}
          />
        )
      }

      const isPriceField = [
        'regularPrice',
        'salePrice',
        'costOfGoods',
        'revenue',
      ].includes(id)

      // Make zone fields editable.
      if (!readOnly && isManagingStock && isZone && props.col.isEditable) {
        return <CellEditable {...props} />
      }

      // Make price fields editable.
      if (!readOnly && isManagingStock && isEditable) {
        return <CellEditable {...props} />
      }

      // Render read only price fields.
      if (isPriceField && renderValue) {
        return <td>{renderValue(props.value)}</td>
      }

      return defaultCellRenderer(props)
    },
    [readOnly, handleClickProductName, renderCogsCell, hasVariationDescColumn],
  )

  const renderRowClassName = useCallback(
    ({ row }) => (row.doNotList ? 'highlight' : ''),
    [],
  )

  const shortcodePaste = useShortcodePaste(filters)

  const getTags = useApi(
    () => ({ action: 'inventoryTags_getAll', json: { context: 'INVENTORY' } }),
    () => [],
    () => ({
      autoPerform: true,
      normalize: (input) => input.rows,
    }),
  )

  const handleCloseTagsPanel = useCallback(() => {
    manageTagsPanel.close()
    getTags.performRequest()
  }, [manageTagsPanel, getTags])

  const handleProductUpdated = useCallback(() => {
    triggerSearch()
  }, [triggerSearch])

  const hasRangeFilter = useMemo(
    () =>
      filters.suppliers.values.length > 0 ||
      intersection(columns.visibleColumnsIds, [
        'revenue',
        'qtySold',
        'inStockRate',
      ]).length > 0,
    [columns.visibleColumnsIds, filters.suppliers.values.length],
  )

  if (apiLoadDiscounts.isLoading) {
    return null
  }
  if (apiLoadZones.isLoading) {
    return null
  }

  return (
    <>
      <PageNavHead
        {...{
          isLoading,
          pageTitle,
          onClickReload: triggerSearch,
        }}
      />
      <PageNavTail
        {...{
          isLoading,
          query,
          handleChangeSearch,
          onPaste: shortcodePaste.handlePaste,
        }}
      >
        {!readOnly ? (
          <TagActionButton
            toggleTagApiAction="inventory_toggleTags"
            {...{
              selectedRows,
              rows,
              tags: getTags.result,
              onClickManage: manageTagsPanel.open,
              onModified: triggerSearch,
            }}
          />
        ) : null}
        {!readOnly ? (
          <AddActionButton
            onAddVariation={handleClickAddVariation}
            onAddSimple={handleClickAddSimple}
          />
        ) : null}
        {canManageDiscounts && !readOnly ? (
          <DiscountActionButton
            {...{ selectedRows, onModified: triggerSearch, apiLoadDiscounts }}
          />
        ) : null}
        {!readOnly ? <ImportActionButton /> : null}
        <ExportInventoryActionButton
          {...{
            requestProps: filters.requestProps,
            query,
            sortColumn,
            sortAsc,
            columns,
          }}
        />
        <ShortcodeActionButton {...{ query, filters }} />
      </PageNavTail>
      <FilterBar {...{ filters, manageColumnsPanel, columns }}>
        <Filters
          {...{
            tags: getTags.result,
            filters,
            hiddenColumns,
            apiLoadZones,
            apiLoadDiscounts,
            visibleColumnIds: columns.visibleColumnsIds,
            hasRangeFilter,
          }}
        />
      </FilterBar>
      <Container fluid>
        <div className="mt-4 manage-inventory animated fadeIn">
          <ManageTagsPanel
            apiPrefix="inventoryTags"
            apiContext="INVENTORY"
            isOpen={manageTagsPanel.isOpen}
            onClose={handleCloseTagsPanel}
          />
          <ManageColumnsPanel
            {...{ columns }}
            isOpen={manageColumnsPanel.isOpen}
            onClose={manageColumnsPanel.close}
          />
          <EditProductPanel
            {...{ readOnly }}
            {...editProductProps}
            onProductUpdated={handleProductUpdated}
            isOpen={editProductPanel.isOpen}
            onClose={editProductPanel.close}
          />
          <Row>
            <Col>
              <Table
                entityName="variations"
                onClickCell={handleClickCell}
                onChangeCell={handleChangeCell}
                {...state}
                {...{
                  setItem,
                  getItem,
                  columnDef: filteredColumnDef,
                  dispatch,
                  renderCell,
                  renderRowClassName,
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default ManageInventory
