export type ManageDropshipOrdersType = FiltersTypeWithUrlUpdater & {
  q: FilterType
}

type FiltersProps = {
  filters: ManageDropshipOrdersType
  visibleColumnIds: string[]
}

export const Filters = (_props: FiltersProps) => {
  return null
}

export default Filters
